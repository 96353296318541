import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1008.000000 756.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,756.000000) scale(0.100000,-0.100000)">
          <path d="M4563 5194 c-277 -278 -503 -512 -503 -521 0 -19 1001 -1023 1020
-1023 19 0 1020 1001 1020 1020 0 16 -1008 1030 -1025 1030 -6 0 -236 -228
-512 -506z m1022 -43 l480 -481 -75 -76 c-302 -306 -903 -904 -908 -904 -4 0
-227 220 -496 489 -427 427 -487 491 -478 507 6 11 224 232 485 493 414 412
477 472 493 463 11 -6 235 -227 499 -491z"/>
<path d="M4977 5234 c-169 -41 -317 -175 -317 -288 0 -92 45 -164 196 -314 74
-72 134 -134 134 -137 0 -3 -15 -5 -33 -5 -21 0 -51 11 -78 30 -42 28 -45 28
-69 13 -14 -9 -34 -28 -45 -42 -11 -14 -33 -40 -50 -57 -65 -68 -115 -126
-115 -132 2 -29 109 -110 203 -152 85 -38 153 -52 250 -52 159 -1 272 46 371
152 76 82 91 141 59 222 -38 97 -111 187 -263 322 l-65 58 48 -6 c26 -3 65
-16 86 -28 38 -22 38 -22 63 -2 35 28 202 213 206 229 4 16 -115 106 -183 140
-119 59 -278 78 -398 49z"/>
<path d="M7196 3138 c-14 -20 -16 -81 -16 -468 0 -334 3 -449 12 -458 20 -20
56 -14 74 14 15 23 17 65 12 466 -5 410 -7 443 -24 455 -25 19 -41 16 -58 -9z"/>
<path d="M4339 3059 c-18 -18 -19 -23 -6 -42 19 -28 50 -34 170 -30 114 5 234
-10 280 -33 41 -22 88 -76 109 -128 48 -116 -8 -257 -127 -319 -35 -18 -63
-21 -235 -26 l-195 -6 -3 -26 c-6 -47 11 -51 220 -47 189 3 194 4 250 31 71
36 129 94 165 166 24 48 27 68 27 141 0 76 -4 92 -32 146 -33 66 -90 122 -157
154 -54 26 -159 39 -317 40 -116 0 -130 -2 -149 -21z"/>
<path d="M5403 3058 c-11 -13 -47 -79 -80 -148 -34 -69 -97 -200 -142 -291
-44 -91 -81 -173 -81 -182 0 -22 37 -46 61 -40 14 4 55 77 141 249 68 134 128
244 135 244 7 0 67 -108 133 -240 71 -143 128 -243 140 -250 24 -13 54 0 65
27 8 22 -291 630 -317 644 -25 13 -32 11 -55 -13z"/>
<path d="M3060 3061 c-152 -49 -212 -164 -137 -262 43 -57 104 -87 207 -100
122 -17 155 -26 190 -55 73 -62 10 -141 -126 -159 -64 -9 -123 7 -189 51 -59
39 -85 37 -85 -6 0 -35 64 -95 117 -109 121 -32 234 -21 312 30 94 62 116 145
58 221 -48 64 -121 97 -241 108 -136 13 -191 46 -184 111 4 42 47 73 125 89
73 16 149 1 214 -41 56 -35 83 -35 87 2 4 32 -32 69 -96 101 -37 18 -66 23
-137 25 -49 1 -101 -1 -115 -6z"/>
<path d="M3852 3058 c-5 -7 -67 -128 -137 -268 -69 -140 -135 -271 -146 -291
-23 -44 -24 -72 -4 -89 35 -29 59 1 160 207 53 109 106 217 117 241 33 70 56
56 115 -70 10 -21 46 -94 81 -163 34 -70 72 -146 83 -171 14 -29 30 -46 45
-50 24 -6 54 10 54 28 0 15 -291 601 -308 621 -18 20 -45 22 -60 5z"/>
<path d="M5881 3033 c-16 -56 -13 -356 3 -426 17 -72 55 -128 115 -166 123
-79 267 -60 362 47 62 71 69 106 69 347 0 227 -2 235 -51 235 -42 0 -49 -37
-49 -252 0 -196 -5 -226 -47 -273 -55 -62 -171 -72 -238 -20 -66 50 -75 84
-75 302 0 105 -4 203 -10 217 -7 20 -17 26 -39 26 -26 0 -31 -5 -40 -37z"/>
<path d="M6536 3048 c-14 -19 -16 -67 -16 -320 0 -285 1 -299 20 -318 23 -23
33 -24 61 -10 17 10 19 22 19 115 0 57 4 107 10 110 12 7 69 -25 210 -118 163
-108 188 -121 216 -108 24 11 32 43 16 67 -4 7 -84 61 -177 120 -212 136 -218
140 -211 152 5 9 135 95 319 212 67 43 77 53 77 79 0 29 -14 41 -48 41 -10 0
-44 -17 -77 -38 -238 -152 -304 -192 -318 -192 -15 0 -17 13 -17 96 0 52 -5
104 -10 115 -14 26 -55 24 -74 -3z"/>
<path d="M6838 2218 c8 -8 12 -54 12 -141 0 -114 2 -128 16 -123 9 3 24 6 35
6 16 0 18 3 9 19 -13 26 -13 114 1 128 8 8 8 13 0 17 -6 4 -11 29 -11 57 l0
49 -37 0 c-29 0 -34 -3 -25 -12z"/>
<path d="M5734 2206 c-71 -31 -54 -124 27 -141 34 -8 79 -42 79 -60 0 -24 -30
-45 -65 -45 -25 0 -40 8 -58 30 -32 37 -37 37 -37 1 0 -25 4 -29 38 -35 63
-10 103 -7 133 11 63 38 45 105 -36 138 -70 28 -75 33 -75 64 0 19 7 31 23 39
32 15 8 14 -29 -2z"/>
<path d="M5807 2204 c7 -3 17 -21 23 -40 12 -35 38 -44 47 -17 6 17 -42 63
-64 62 -10 0 -13 -3 -6 -5z"/>
<path d="M6952 2132 c-9 -3 -5 -11 13 -27 19 -19 25 -34 25 -68 0 -47 -14 -73
-45 -80 -13 -3 -8 -5 13 -6 48 -2 82 36 82 89 0 23 -4 50 -10 60 -11 20 -58
40 -78 32z"/>
<path d="M6280 2111 c5 -11 10 -39 10 -63 0 -87 25 -112 87 -90 22 8 24 11 8
11 -35 2 -45 22 -45 93 l0 68 -35 0 c-32 0 -35 -2 -25 -19z"/>
<path d="M6411 2109 c6 -12 9 -50 7 -88 l-3 -66 34 -3 c28 -3 33 -1 27 13 -3
9 -6 50 -6 91 l0 74 -35 0 c-33 0 -35 -1 -24 -21z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
